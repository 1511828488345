<template>
  <div class="row">
    <div class="col">
      <b-card title="Services">
        <blueprint-data api-route-path="inventory/services" :new-default-values="newDefaultValues" :fields="fields">
          <template v-slot:default="{ state, item }">
            <b-form-group label="Name" label-for="name">
              <b-form-input id="name" v-model="item.name" :state="state('name')" type="text" placeholder="Name" />
            </b-form-group>
            <b-form-group label="Description" label-for="description">
              <b-form-textarea id="description" v-model="item.description" rows="3" max-rows="6" :state="state('description')" type="text" />
            </b-form-group>
            <b-form-group label="Terms & Conditions url" label-for="terms">
              <b-form-input id="terms" v-model="item.terms" :state="state('terms')" type="text" placeholder="http://..." />
            </b-form-group>
            <b-form-group label="Type" label-for="type">
              <b-form-select id="role" v-model="item.type" :state="state('type')" :options="types" />
            </b-form-group>
          </template>
        </blueprint-data>
      </b-card>
    </div>
  </div>
</template>

<script>

export default {
  data () {
    return {
      newDefaultValues: {
        type: 'MONTH_SUBSCRIPTION',
      },
      types: [
        { value: 'MONTH_SUBSCRIPTION', text: 'Monthly Subscription' },
        { value: 'MONTH_SUBSCRIPTION_PER_M2', text: 'Monthly Subscription divided by squere meters' },
        { value: 'PER_ORDER', text: 'Per Order' },
      ],
      fields: [
        {
          key: 'name',
          sortable: true,
        },
        {
          key: 'description',
          sortable: false,
        },
        {
          key: 'terms',
          sortable: false,
        },
        {
          key: 'type',
          sortable: true,
        },
      ],
    };
  },
};
</script>
